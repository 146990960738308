import {defineStore} from "pinia"
import SimpleCrypto from "simple-crypto-js"
import axios_api from "@/config/axios/axios"
import * as utils from "@/core/helpers/utils"
import {toast} from "vue3-toastify"
import SettingsModel from "@/modules/settings/settings/entities/settings.model"

const simpleCrypto = new SimpleCrypto(process.env.VUE_APP_TOKEN_KEY)
export const siteStore = defineStore("site", {
    state: () => ({}),
    getters: {
        jwt() {
            return simpleCrypto.decrypt(localStorage.getItem(process.env.VUE_APP_HASH))
        },
        refresh_token() {
            return simpleCrypto.decrypt(localStorage.getItem("refresh_" + process.env.VUE_APP_HASH))
        },
        user() {
            return localStorage.getItem(process.env.VUE_APP_HASH) ? Object.freeze(utils.extract_user_data("user", simpleCrypto.decrypt(localStorage.getItem(process.env.VUE_APP_HASH)))) : null
        },
        settings() {
            return localStorage.getItem("settings") ? Object.freeze(simpleCrypto.decrypt(localStorage.getItem("settings"))) : null
        }
    },
    actions: {
        login(params = {}) {
            return axios_api.post("login", params).then((response) => {
                const token_hash = simpleCrypto.encrypt(response.data.token)
                const refresh_token_hash = simpleCrypto.encrypt(response.data.refresh_token)
                localStorage.setItem(process.env.VUE_APP_HASH, token_hash)
                localStorage.setItem("refresh_" + process.env.VUE_APP_HASH, refresh_token_hash)
                return response
            }).catch(error => {
                throw error
            })
        },
        setSettings() {
            const {onResult} = SettingsModel.useGet(1, {})
            onResult((response) => {
                const settings = simpleCrypto.encrypt(response.data)
                localStorage.setItem("settings", settings)
            })
        },
        refresh() {
            return axios_api.post("refresh", {}).then(async (response) => {
                const token_hash = await simpleCrypto.encrypt(response.data.token)
                const refresh_token_hash = await simpleCrypto.encrypt(response.data.refresh_token)
                localStorage.setItem(process.env.VUE_APP_HASH, token_hash)
                localStorage.setItem("refresh_" + process.env.VUE_APP_HASH, refresh_token_hash)
                return response
            }).catch(() => {
                toast("Ha expirado su sesion", {
                    "theme": "colored",
                    "type": "error",
                    "dangerouslyHTMLString": true,
                    "autoClose": 1000,
                })
                setTimeout(() => {
                }, 2000)
            })
        },
        logout(params = {}) {
            return axios_api.post("logout", params).then((response) => {
                return {success: true, response: response}
            }).catch(() => true).finally(() => {
                localStorage.removeItem(process.env.VUE_APP_HASH)
                localStorage.removeItem("refresh_" + process.env.VUE_APP_HASH)
                location.reload()
            })
        }
    }
})
