export default [
    {
        path: "/products_no_refunds",
        name: "products_no_refunds",
        component: () => import("@/modules/managment/products/views/ProductsNoRefundsView.vue"),
    },
    {
        path: "/products_maker",
        name: "products_maker",
        component: () => import("@/modules/managment/products/views/ProductsMakerView.vue"),
    }
]
