// Styles
import "@mdi/font/css/materialdesignicons.css"
import "vuetify/styles"
import {es, ca} from "vuetify/locale"
// Vuetify
import {createVuetify} from "vuetify"

export default createVuetify({
        locale: {
            locale: "es",
            fallback: "sv",
            messages: {ca, es},
        },
        VDatatable: {
            style: 'color: blue',
        }
    }
)
