/**Generate by ASGENS
*@author Charlietyn
*@date Tue Jun 20 13:00:51 GMT-04:00 2023
*@time Tue Jun 20 13:00:51 GMT-04:00 2023
*/
const requireModule = require.context("@/", true, /(route.js$)/);
let routes = []
requireModule.keys().forEach((fileName) => {
        const elem = {...requireModule(fileName)}
    routes=routes.concat(...Object.values(elem))
    }
)
export default routes;
