<template>
  <Home v-if="Object.keys(auth).length"/>
  <Login v-else/>
</template>

<script setup>
import {onMounted, provide, reactive, ref} from "vue"
import Home from "@/layout/home/Home.vue"
import Login from "@/layout/login/Login.vue"
import {siteStore} from "@/config/store/site.store"
import {toast} from "vue3-toastify"

const site = siteStore()
const loading = ref(false)
let pageSize = ref(null)
provide("auth", {login, logout})
provide("global", {loading})
provide("pagination", {pageSize})
const auth = reactive({})


async function login(params) {
  await site.login(params).then(() => {
    loading.value = false
    window.location.href = "/refunds"
  }).catch((error) => {
    throw error
  })
  Object.assign(auth, site.user)
}

function logout() {
  site.logout().then(() => {
    toast("Ud se ha deslogueado correctamente", {
      "theme": "colored",
      "type": "success",
      "dangerouslyHTMLString": true
    })
    Object.assign(auth, {})
  })
}

onMounted(() => {
  Object.assign(auth, site.user)
  if (site.user)
    if (site.user.role === 1)
      pageSize.value = 100
    else
      pageSize.value = 50
})

</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 5px;
}

thead th{
  font-size: 18px;
  font-weight: bold !important;
}

.v-btn {
  text-transform: none;
}

v-table tbody tr {
  background-color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: white;
}

.v-btn-view {
  background-color: #ff6f05;
  width: 150px;
  height: 50px;
  color: white;
  margin-right: 100px
}

.label-input{
  font-size: 1rem;
  font-weight: bold;
}

.detail-input{
  font-size: 1.3rem;
  line-height: normal;
}

.v-chip-datatable{
  width: 180px;
  justify-content: center;
  text-align: center;
}

.button-card {
  font-size: 20px;
  background-color: transparent !important;
  min-width: 0;
  color: black;
  padding-left: 0;
}

.v-btn-new {
  background-color: #ff6f05;
  width: 160px;
  height: 10px;
  color: white;
  margin-right: 10px
}

.div-border {
  border-left: solid;
  border-color: #ff6f05;
}

.v-select-form {
  max-width: 250px;
  width: 33rem;
  padding-top: 15px
}

.v-btn-modal {
  background-color: #ff6f05;
  width: 80px;
  height: 50px;
  color: white;
}

</style>
