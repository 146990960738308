<template>  
  <v-app>

      <v-navigation-drawer
          v-model="drawer"
          app
          class="bg-black mx-auto"
          width="310"
          elevation="15"
      >
        <div title="My Application" subtitle="Vuetify">
          <v-img style="cursor: pointer;" @click="goIndex" class="logo pa-5 px-50" src="/logo.png"/>
        </div>

        <v-list>
          <v-list-item @click="goIndex" class="d-flex align-items-start" title="RMA"></v-list-item>
          <v-list-group
            value="Devoluciones"
            class="list-group"
          >
            
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"                  
                  prepend-icon="mdi-cash-refund"
                  class="tile-item"                  
                  key="2"
              >
                <v-list-item-content>
                  <v-list-item-title>Devoluciones
                    <v-chip
                        size="small"
                        variant="flat"
                        color="#ff6f05"
                    >
                      {{ totalRefundPendient }}
                    </v-chip>

                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
                link
                class="tile-item"
                key="144"
                :title="site.user.role === 2 ? 'Mis Devoluciones' : 'Devoluciones'"
                to="/refunds"
                value="myDevolution"
            ></v-list-item>
            <v-list-item
                class="tile-item"
                title="Nueva Devolución"
                to="/newrefunds"
                value="newDevolution"
                key="1444"
            ></v-list-item>
            <v-list-item
                class="tile-item"
                v-if="false"
                key="334233"
                title="Historico de Devolucion"
                to="/historic_refund"
                value="historic_refund"
            ></v-list-item>
          </v-list-group>

          <v-list-group value="Guarantee">
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"
                  class="tile-item"
                  prepend-icon="mdi-shield"
                  key="14664"
              >
                <v-list-item-content key="333">
                  <v-list-item-title>Garantías
                    <v-chip
                        size="small"
                        variant="flat"
                        color="#ff6f05"
                    >
                      {{ totalGuaranteePendient }}
                    </v-chip>

                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
                class="tile-item"
                :title="site.user.role === 2 ? 'Mis Garantías' : 'Garantías'"
                to="/guarantee"
                value="myGuarantee"
                key="122"
            ></v-list-item>
            <v-list-item
                class="tile-item"
                key="331"
                title="Nueva Garantía"
                to="/newguarantee"
                value="newGuarantee"
            ></v-list-item>
            <v-list-item
                class="tile-item"
                v-if="false"
                key="3342"
                title="Historico de Garantía"
                to="/historic_guarantee"
                value="historic_guarantee"
            ></v-list-item>
          </v-list-group>

          <v-list-group value="Products" v-if="site.user.role===1">
            <template v-slot:activator="{ props }">
              <v-list-item
                  class="tile-item"
                  v-bind="props"
                  prepend-icon="mdi-application-edit-outline"
                  title="Productos"
                  key="3"
              >
              </v-list-item>
            </template>

            <v-list-item
                class="tile-item"
                key="13"
                title="Imp. no devolución"
                to="/products_no_refunds"
                value="products_no_refunds"
            ></v-list-item>
            <v-list-item
                class="tile-item"
                key="13"
                title="Imp. a fabricante"
                to="/products_maker"
                value="products_maker"
            ></v-list-item>
          </v-list-group>

          <v-list-group value="Global" v-if="site.user.role===1">
            <template v-slot:activator="{ props }">
              <v-list-item
                  class="tile-item"
                  v-bind="props"
                  prepend-icon="mdi-account-circle"
                  title="Global"
              >
                <template v-slot:prepend>
                  <v-icon :icon="'mdi-layers'"></v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
                class="tile-item"
                title="Parámetros"
                to="/settings"
                value="settings"
            >
            </v-list-item>
            <v-list-item
                class="tile-item"
                title="Logs del Sistema"
                to="/system_logs"
                value="system_logs"
            >
            </v-list-item>
          </v-list-group>

          <v-list-group value="Reception" v-if="site.user.role===1">
            <template v-slot:activator="{ props }">
              <v-list-item
                  class="tile-item"
                  @click="changeRMA(true)"
                  prepend-icon="mdi-account-circle"
                  title="Recepción RMA"
              >
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>

      </v-navigation-drawer>

    <v-app-bar
      flat
      style="border-width: 0;"
    >
      <div class="left d-lg-none d-md-block">
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>        
      </div>

      <div class="left d-none d-sm-none d-lg-block" style="padding-left: 31px;">
        <v-toolbar-title>
          <b class="mytitle">Portal del Cliente</b>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <v-menu>
        <template v-slot:activator="{ props }">
          <div>
            Hola {{ site.user.username }}
            <v-btn 
              icon="mdi-account" 
              variant="text"
              v-bind="props"
            ></v-btn>
          </div>
        </template>

        <div class="message">
          <v-row class="mt-0">
            <v-icon icon="mdi-account" style="padding-right: 20px"></v-icon>
            <p style="padding-right: 20px">{{ site.user.username }} </p>
            <v-icon icon="mdi-logout" @click="logout"></v-icon>
          </v-row>
        </div>
      </v-menu>  
    </v-app-bar>

    <v-main style="background-color: #f4f7fa">
      <v-container fluid>
        <router-view></router-view>
      </v-container>      
    </v-main>

    <app-footer/>
  </v-app>

  <div class="text-center pa-4">
    <v-dialog
      v-model="showDialogRMA"
      max-width="500"
      persistent
    >
    <v-card
    >        
    <v-card-title class="text-left">
      <div style="padding: 0">
        <div style="padding-left: 20px">Recepción de RMA</div>
      </div>
    </v-card-title>

    <template v-slot:append>
      <v-row>
        <v-btn
            icon="$close"
            variant="text"
            @click="showDialogRMA = false"
        ></v-btn>
      </v-row>
    </template>

      <v-card-text>
        <div>
          <v-col cols="12" sm="12" md="12">
            <div class="text-left" style="padding-button: 40px">Código RMA</div>
            <v-text-field
                ref="refRMA"
                v-model="codeRMA"
                autofocus                
                @keydown.enter="saveRMA()"
                variant="outlined"
                color="#ff6f05"
            ></v-text-field>
          </v-col>
  
        </div>
      </v-card-text>

      <template v-slot:actions>
        <v-col cols="12" sm="12" md="12" class="text-right" style="padding-rigth: 10px">
          <v-btn         
            class="v-btn-new"    
            @click="saveRMA()">
            Guardar
          </v-btn>
        </v-col>
      </template>
    </v-card>
  </v-dialog>
</div>
  
</template>

<script setup>
import { computed, unref } from 'vue'
import { useDisplay } from 'vuetify'
import {siteStore} from "@/config/store/site.store"
import {inject, onMounted, ref, reactive} from "vue"
import AppFooter from "@/layout/home/components/AppFooter.vue"
import GuaranteeModel from "@/modules/managment/guarantee/entities/guarantee.model"
import RefundModel from "@/modules/managment/refund_request/entities/refund_request.model"
import route from "@/config/router"
import {useRouter} from "vue-router"
import {toast} from "vue3-toastify"

const {logout} = inject("auth")
const site = siteStore()
const router=useRouter()
let totalGuaranteePendient = ref(0)
let totalRefundPendient = ref(0)
let drawer = ref(true)
let showDialogRMA = ref(false)
let codeRMA = ref('')
let modelGuarantee = reactive([])
let modelRefund = ref([])
let refRMA = ref()

onMounted(() => {
  site.setSettings()
  console.log(site.user)
})

const display = useDisplay()

const isMobile = computed(() => {
  return unref(display.mobile)
})

function saveRMA(){
  if(codeRMA.value.substr(0, 1) === 'G'){
    let separarCadena = codeRMA.value.split("-")
    let rmaId = Number(separarCadena[2]) - 100;

    const {onResult: onResultGuarantee} = GuaranteeModel.useGetMany({attr: {code: codeRMA.value}}, {})
    onResultGuarantee((result) => {

      if(result.data.data.length != 0){
        Object.assign(modelGuarantee, result.data.data[0])
        saveGuarantee()
      }else{  
        toast("Código RMA inexistente", {
          "theme": "colored",
          "type": "info",
          "dangerouslyHTMLString": true
        })
      } 
    })
  }else{
    let separarCadena = codeRMA.value.split("-")
    let rmaId = Number(separarCadena[2]) - 100;

    const {onResult: onResultRefund} = RefundModel.useGetMany({attr: {code: codeRMA.value}}, {})
    onResultRefund((result) => {

      if(result.data.data.length != 0){
        Object.assign(modelRefund, result.data.data[0])
        saveRefund()
      }else{  
        toast("Código RMA inexistente", {
          "theme": "colored",
          "type": "info",
          "dangerouslyHTMLString": true
        })
      } 
    })    
  }
  refRMA.value.focus();
  codeRMA.value = ""
}

function saveGuarantee(){
  modelGuarantee.status_id = 2
  
  const guaranteeModel = new GuaranteeModel(modelGuarantee)  
  delete guaranteeModel.charge

  const {onResult} = guaranteeModel.useSave()
  onResult((result) => {
    if (result.data.success) {
      toast("Estado cambiado a recibido", {
          "theme": "colored",
          "type": "success",
          "dangerouslyHTMLString": true
        })
    }
  })
}

function saveRefund(){
  modelRefund.status_id = 2

  const refundModel = new RefundModel(modelRefund)  
  delete refundModel.charge

  const {onResult} = refundModel.useSave()
  onResult((result) => {
    if (result.data.success) {
      toast("Estado cambiado a recibido", {
          "theme": "colored",
          "type": "success",
          "dangerouslyHTMLString": true
        })
    }
  })
}

function changeRMA(value){
  showDialogRMA.value = value
}

function goIndex(){
  router.push({name:'home'})
}

const {onResult: onResultGuarantee} = GuaranteeModel.useGetMany({attr: {status_id: 1}}, {})
onResultGuarantee((result) => {
  totalGuaranteePendient.value = result.data.data.length
})

const {onResult: onResultRefund} = RefundModel.useGetMany({attr: {status_id: 1}}, {})
onResultRefund((result) => {
  totalRefundPendient.value = result.data.data.length
})

</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.my-class .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.bg-active {
  background-color: white;
  color : white !important;
}

nav {
  padding: 5px;
}

@media (max-width: 480px) {
  .size-controller {
    width: 100%;
  }

  .mobile-100-percent {
    width: 100% !important;
  }
}

/*para mobile*/
@media (min-width:320px)  {
  .v-app-bar{
    background-color: black !important;
    color: white !important;
  }
}

/*para desktop*/
@media (min-width:961px) {
  .v-app-bar{
    background-color: #f4f7fa !important;
    color: black !important;
  }  
}

.left {
  float: left;
}

.right {
  float: right;
}

.accordion-right {
  justify-content: flex-end;
  display: flex;
  width: 170px;
  align-items: center;
  position: absolute;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
  height: fit-content;
  font-size: 14px;
  padding-right: 5px;
}

.btn-user{
  font-size: 12px;
  padding-right: 175px;
}

.tile {
  background: #ff6f05;
  color: #f0f1f1;
  border:2px solid brown;
  border-radius:12px;  
}

.tile-item {
  text-align: left;
}

.tile-item:hover {
  color: #ff6f05;
  border-left: solid;
  border-color: chocolate;
}

.tile-item:focus {
  color: #ff6f05;
  border-left: solid;
  border-color: chocolate;
}

v-list-group:focus{
  color: #ff6f05;
  border-left: solid;
  border-color: chocolate;
}

.list-group:focus {
  color: #ff6f05;
  border-left: solid;
  border-color: chocolate;
}

.mytitle {
  font-size: 2.0rem;
}

.v-btn-new {
  background-color: #ff6f05;
  width: 150px;
  height: 50px;
  color: white;
  margin-right: 10px
}

.message {
	position: relative;
	max-width: 50em;
  color: white;
	
	background-color: #ff6f05;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.message::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 9.4em; 
	border: .75rem solid transparent;
	border-top: none;

	border-bottom-color: #ff6f05;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

</style>
