
export default [
    {
        path: "/",
        name: "home",
        component: () => import("@/modules/home/views/HomeView.vue"),
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/modules/home/views/DashBoard.vue"),
    },
    {
        path: '/layout',
        name: 'layout',
        component: () => import("@/modules/home/views/layout.vue"),
    },
    {
        path: '/layout1',
        name: 'layout1',
        component: () => import("@/modules/home/views/layout1.vue"),
    },
    {
        path: '/layout2',
        name: 'layout2',
        component: () => import("@/modules/home/views/layout2.vue"),
    },
    {
        path: '/about',
        name: 'about',
        component: () => import("@/modules/home/views/DashBoard.vue"),
    }
]
