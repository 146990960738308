
export default [
    {
        path: "/historic_guarantee",
        name: "historic_guarantee",
        component: () => import("@/modules/managment/historic/views/HistoricGuaranteeView.vue"),
    },
    {
        path: "/historic_refund",
        name: "historic_refund",
        component: () => import("@/modules/managment/historic/views/HistoricRefundsView.vue"),
    }
]
