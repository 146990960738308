<template>

  <v-footer inset app class="v-footer-style">
    <div class="container bg-footer">
      <v-col cols="12" sm="12" md="12">
        <row>
          <p style="font-size: 14px">
            <v-icon>mdi-copyright</v-icon>
            2024 MSMC S.L. All rights reserved. <a :href="urlFooter" style="color: black" target="_blank"><strong> {{nameFooter}}</strong></a></p>
        </row>
      </v-col>
    </div>
  </v-footer>

</template>

<script setup>
import {ref, watch} from "vue"
import {useRoute} from "vue-router"
import {siteStore} from "@/config/store/site.store"

const nameFooter = ref("")
const urlFooter = ref("")
const route = useRoute()
const site = siteStore()
watch(route, (newValue) => {
  if (site.user)
    if (site.user.role === 2) {
      if (newValue.fullPath.includes("refu")) {
        nameFooter.value = "Leer Política de Devolución"
        urlFooter.value = "/Devoluciones.pdf"
      }
      if (newValue.fullPath.includes("guaran")) {
        nameFooter.value = "Leer Política de Garantía"
        urlFooter.value = "/Garantias.pdf"
      }
    }
})

</script>

<style>
.v-footer-style {
  /*  padding-bottom: 10px;*/
  /*background-color: #f4f7fa;*/
  background-color: #f4f7fa;
  color: black;
  align-items: center;
  justify-content: center;
}

.bg-footer {
  align-items: center;
  background-color: #f4f7fa;
  color: black;
  position: relative;
  bottom: 0;
  height: 30px;
}

</style>
