/**Generate by ASGENS
 *@author Charlietyn
 *@date Wed Mar 20 22:27:03 GMT-04:00 2024
 *@time Wed Mar 20 22:27:03 GMT-04:00 2024
 */
import RBaseModel from "@/core/base/RestBase"
import {columns} from "@/modules/managment/guarantee/entities/guarantee.columns"
import moment from "moment"
import {useAxiosFetch} from "@/core/composable/useAxiosFetch"
import axios_api from "@/config/axios/axios"
import {ContentTypeEnum} from "@/core/enums/httpEnum"

const url = "managment/guarantee"
const axios = axios_api
const header = ContentTypeEnum.JSON
export default class GuaranteeModel extends RBaseModel {

    id
    status_id
    delivery_note_id
    mounting_date
    claim_date
    claim_km
    claim_actual_km
    brand_vehicle
    plate_vehicle
    vin_vehicle
    motive
    details
    files
    charge
    ean_code
    note
    days_number

    constructor(attributes = null) {
        super()
        if (attributes != null) {
            this.id = attributes.id || undefined
            this.status_id = attributes.status_id || 1
            this.delivery_note_id = attributes.delivery_note_id || null
            this.mounting_date = attributes.mounting_date ? moment(attributes.mounting_date).format("YYYY-MM-DD") : null
            this.claim_date = attributes.claim_date ? moment(attributes.claim_date).format("YYYY-MM-DD") : null
            this.claim_km = attributes.claim_km || null
            this.claim_actual_km = attributes.claim_actual_km || null
            this.brand_vehicle = attributes.brand_vehicle || null
            this.plate_vehicle = attributes.plate_vehicle || null
            this.vin_vehicle = attributes.vin_vehicle || null
            this.motive = attributes.motive || null
            this.details = attributes.details || null
            this.files = attributes.files || []
            this.charge = attributes.charge || 0
            this.ean_code = attributes.ean_code || null
            this.note = attributes.note || null
            this.days_number = attributes.days_number || null            
        }
        this.url = url
        this.modelName = this.class_name()
    }

    set_attributes(attributes = null) {
        if (attributes != null) {
            this.id = attributes.id
            this.status_id = attributes.status_id
            this.delivery_note_id = attributes.delivery_note_id
            this.mounting_date = moment(attributes.mounting_date).format("YYYY-MM-DD")
            this.claim_date = moment(attributes.claim_date).format("YYYY-MM-DD")
            this.claim_km = attributes.claim_km
            this.claim_actual_km = attributes.claim_actual_km
            this.brand_vehicle = attributes.brand_vehicle
            this.plate_vehicle = attributes.plate_vehicle
            this.vin_vehicle = attributes.vin_vehicle
            this.motive = attributes.motive
            this.details = attributes.details
            this.files = attributes.files
            this.charge = attributes.charge
            this.ean_code = attributes.ean_code
            this.note = attributes.note
            this.days_number = attributes.days_number
        }
        this.url = url
        this.modelName = this.class_name()
    }

    static get url() {
        return url
    }

    get url() {
        return url
    }

    static columns = columns

    getId() {
        return this.id
    }

    class_name() {
        return "Guarantee"
    }

    static useGetMany(params, options, axiosRequestConfig) {
        return useAxiosFetch(axios, {
            url: "managment/vguarantee",
            method: "get",
            params,
            header, ...axiosRequestConfig
        }, options)
    }

}

