/**Generate by ASGENS
 *@author Charlietyn
 *@date Wed Mar 20 22:27:03 GMT-04:00 2024
 *@time Wed Mar 20 22:27:03 GMT-04:00 2024
 */
import RBaseModel from "@/core/base/RestBase"

const url = "settings/settings"


export default class SettingsModel extends RBaseModel {

    id
    refund_days
    guarantee_days

    constructor(attributes = null) {
        super()
        if (attributes != null) {
            this.id = attributes.id || undefined
            this.refund_days = attributes.refund_days || null
            this.guarantee_days = attributes.guarantee_days || null
        }
        this.url=url
        this.modelName=this.class_name()
    }

    set_attributes(attributes = null) {
        if (attributes != null) {
            this.id = attributes.id
            this.refund_days = attributes.refund_days
            this.guarantee_days = attributes.guarantee_days
        }
        this.url=url
        this.modelName=this.class_name()
    }

    static get url() {
        return url
    }

    get url() {
        return url
    }


    getId() {
        return this.id
    }

    class_name() {
        return "Settings"
    }


}

