/**Generate by ASGENS
 *@author Charlietyn
 *@date Wed Mar 20 22:27:03 GMT-04:00 2024
 *@time Wed Mar 20 22:27:03 GMT-04:00 2024
 */

import RBaseModel from "@/core/base/RestBase"
import {columns} from "@/modules/managment/refund_request/entities/refund_request.columns"
import {useAxiosFetch} from "@/core/composable/useAxiosFetch"
import axios_api from "@/config/axios/axios"
import {ContentTypeEnum} from "@/core/enums/httpEnum"

const url = "managment/refund_request"
const axios = axios_api
const header = ContentTypeEnum.JSON

export default class Refund_request extends RBaseModel {

    id
    status_id
    delivery_note_id
    description
    charge
 //   ean_code
    states
   // files
    note

    constructor(attributes = null) {
        super()
        if (attributes != null) {
            this.id = attributes.id || undefined
            this.status_id = attributes.status_id || null
            this.delivery_note_id = attributes.delivery_note_id || null
            this.description = attributes.description || null
            this.charge = attributes.charge || 0
            this.states = attributes.states || null
            this.note = attributes.note || null
       //     this.files = attributes.files || null
        }
        this.url = url
        this.modelName = this.class_name()
    }

    set_attributes(attributes = null) {
        if (attributes != null) {
            this.id = attributes.id
            this.status_id = attributes.status_id
            this.delivery_note_id = attributes.delivery_note_id
            this.description = attributes.description
            this.charge = attributes.charge
            this.states = attributes.states
            this.note = attributes.note
       //     this.files = attributes.files
        }
        this.url = url
        this.modelName = this.class_name()
    }

    static get url() {
        return url
    }

    get url() {
        return url
    }

    static columns = columns

    getId() {
        return this.id
    }

    class_name() {
        return "Refund_request"
    }

    static useGetMany(params, options, axiosRequestConfig) {
        return useAxiosFetch(axios, {
            url: "managment/vrefund_request",
            method: "get",
            params,
            header, ...axiosRequestConfig
        }, options)
    }

}

