import {createApp} from "vue"
import {createPinia} from "pinia"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import {loadFonts} from "./plugins/webfontloader"
import router from "./config/router"
import "./assets/css/app.css"
import BootstrapVue3 from "bootstrap-vue-3"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue-3/dist/bootstrap-vue-3.css"
import 'vue3-toastify/dist/index.css';
import './assets/css/custom.css'

import {updateGlobalOptions} from "vue3-toastify"
updateGlobalOptions({ rtl: true });
loadFonts()
const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(BootstrapVue3)
app.mount("#app")
