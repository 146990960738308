import axios_api from "@/config/axios/axios"
import {useAxiosFetch} from "@/core/composable/useAxiosFetch"
import {ContentTypeEnum} from "@/core/enums/httpEnum"
import {objectToFormData} from "@/core/helpers/utils"

let headers = ContentTypeEnum.JSON
const axios = axios_api
export default class RBaseModel {
    isFormData
    changedAttributes
    modelName
    url

    constructor() {
        this.isFormData = false
        this.changedAttributes = {}
    }

    getId() {
        return null
    }

    params(self = true) {
        // const value = Object.assign({}, self ? this : this.getId() ? this.changedAttributes : this)
        let value = Object.assign({}, self ? this : this.getId() ? this.changedAttributes : this)
        value = this.clean(value)
        return this.isFormData ? this.formData() : value
    }

    clean(value) {
        const keys=Object.keys(value)
        const deleteKeys=[];
        Object.values(value).forEach((e,index)=>{
            e==null?deleteKeys.push(keys[index]):false
        })
        deleteKeys.forEach(e=>{
           delete value[e]
        })
        delete value.changedAttributes
        delete value.isFormData
        delete value.url
        delete value.modelName

        return value
    }

    formData() {
        // const formdata = convertToFormData(this.getId() ? this.changedAttributes : this)
        let value = Object.assign({}, this)
        value = this.clean(value)
        const formdata = objectToFormData(value)
        if (this.getId()) {
            formdata.append("_method", "PATCH")
        }
        return formdata
    }

    static useGetMany(params, options, axiosRequestConfig) {
        return useAxiosFetch(axios, {url: this.url, method: "get", params, headers, ...axiosRequestConfig}, options)
    }

    static useGet(id, params, options, axiosRequestConfig) {
        const url = this.url + "/" + id
        return useAxiosFetch(axios, {url, method: "get", params, headers, ...axiosRequestConfig}, options)
    }

    static useUpdate(id, params, options, axiosRequestConfig) {
        const url = this.url + "/" + id
        return useAxiosFetch(axios, {
            url,
            method: "patch",
            data: params,
            headers, ...axiosRequestConfig
        }, options)
    }

    static useDeleteMany(params, options, axiosRequestConfig) {
        return useAxiosFetch(axios, {
            url: this.url,
            method: "delete",
            data: params,
            headers, ...axiosRequestConfig
        }, options)
    }

    static useDeleteId(id, params, options, axiosRequestConfig) {
        const url = this.url + "/" + id
        return useAxiosFetch(axios, {url, method: "delete", params, headers, ...axiosRequestConfig}, options)
    }

    static useCustomFetch(method, endpoint, data, params, axiosRequestConfig, options,) {
        const url = this.url + "/" + endpoint
        return useAxiosFetch(axios, {url, method, data, params, ...axiosRequestConfig}, options)
    }

    useCreate(options, axiosRequestConfig) {
        return useAxiosFetch(axios, {
            url: this.url,
            method: "post",
            data: this.params(),
            headers, ...axiosRequestConfig
        }, options)
    }

    useUpdate(options, axiosRequestConfig) {
        const url = this.url + "/" + this.getId()
        return useAxiosFetch(axios, {
            url,
            method: "patch",
            data: this.params(),
            headers, ...axiosRequestConfig
        }, options)
    }

    useSave(options, axiosRequestConfig) {
        headers=this.isFormData?ContentTypeEnum.FORM_DATA:headers
        return this.getId()
            ? this.useUpdate(options, axiosRequestConfig)
            : this.useCreate(options, axiosRequestConfig)
    }

    useDelete(params, options, axiosRequestConfig) {
        const url = this.url + "/" + this.getId()
        return useAxiosFetch(axios, {url, method: "delete", params, headers, ...axiosRequestConfig}, options)
    }

    useValidate(params, options, axiosRequestConfig) {
        const url = this.url + "/validate"
        return useAxiosFetch(axios, {
            url,
            method: "post",
            data: params,
            headers, ...axiosRequestConfig
        }, options)
    }

    static show_columns(columns_name, show = true) {
        const columns = columns_name == null ? this.columns : columns_name.map((element) => {
            return this.columns.find((c) => c.key === element)
        })
        return columns_name == null ? this.columns : show ? columns : this.columns.filter((element) => {
            return columns_name.indexOf(element.key) === -1
        })
    }
}

