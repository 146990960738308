import {createRouter, createWebHistory} from "vue-router"
import routes from "@/core/helpers/router.load"

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
})

export default router
