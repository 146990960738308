
export default [
    {
        path: "/refunds",
        name: "refunds",
        component: () => import("@/modules/managment/refund_request/views/RefundsView.vue"),
    },
    {
        path: '/newrefunds',
        name: 'newrefunds',
        component: () => import("@/modules/managment/refund_request/views/NewRefund.vue"),
    },
    {
        path: '/detailrefund/:id',
        name: 'detailrefund',
        props: true,
        component: () => import("@/modules/managment/refund_request/views/DetailRefund.vue"),
    },
]
