<template>
  <v-row>

    <v-col cols="12" sm="6" md="8">

      <div class="d-none d-sm-none d-md-block">
        <v-img
            class="bg-grey-lighten-2 w-full radio-group-desktop"
            height="99vh"
            :cover="true"
            src="/fondo.jpg"
        ></v-img>
      </div>

      <div class="d-md-none d-sm-block mb-20">
        <div class="p-3 bg-black">
          <v-img
            src="/logo.png"
          ></v-img>
        </div>
      </div>
    
    </v-col>

    <v-row
        class="d-flex justify-content-center"
        align="center"
        justify="center">

      <v-col cols="12" sm="6" md="4">
        <v-sheet class="mx-auto" width="300">
          <v-icon
              size="60"
              color="#75dcb4"
          >
            mdi-lock-open-outline
          </v-icon>


          <h5 class="custom-font font-setting pt-8">Login</h5>
          <v-form class="mt-10" fast-fail @submit.prevent>
            <v-row v-if="error"
                   class="d-flex justify-content-center mb-3"
                   align="center"
                   justify="center">
              <v-alert
                  color="#C51162"
                  icon="mdi-material-design"
                  theme="dark"
                  border
              >
                {{ message_error }}
              </v-alert>
            </v-row>
            <v-label class="mb-1 float-left">Email</v-label>
            <v-text-field
                v-model="user.email"
                clearable
                variant="outlined"
                height="200px"
                color="blue"
            ></v-text-field>

            <v-label class="mb-1 float-left">Password</v-label>
            <v-text-field
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'"
                v-model="user.password"
                variant="outlined"
                color="blue"
                box-shadow="0 0 6px blue"
                @click:append-inner="visible = !visible">
            </v-text-field>

            <v-btn class="mt-2 v-submit" type="button" @click="loginAction" :loading="loading" block color="#ff6f05">
              Administrador
            </v-btn>
            <v-btn class="mt-2 v-submit" type="button" @click="loginActionClient" :loading="loading" block color="#ff6f05">
              Cliente
            </v-btn>

          </v-form>

        </v-sheet>

      </v-col>
    </v-row>

  </v-row>

  <div class="left d-lg-none d-md-block">
    <div class="container bg-footer">
      <v-col cols="12" sm="12" md="12">
        <row>
          <p style="font-size: 14px">
            <v-icon>mdi-copyright</v-icon>
            2024 MSMC S.L. All rights reserved. <a :href="urlFooter" style="color: black" target="_blank"><strong> {{nameFooter}}</strong></a></p>
        </row>
      </v-col>
    </div>
  </div>

</template>

<script setup>
import {inject, reactive, ref} from "vue"
import Logo from "@/layout/home/components/Logo.vue"

const visible = ref(false)
const {login} = inject("auth")
const {loading} = inject("global")
let user = reactive({"email": "admin_msmc@gmail.com", "password": "admin_msmc"})
const error = ref(false)
const message_error = ref("Error en usuario o contaseña")

function loginAction() {
  loading.value = true
  login(user).catch((errorResponse) => {
    if (errorResponse.response.statusText.toString().startsWith("Cors"))
      message_error.value = "Ocurrio un error, por favor contacte al administrador"
    error.value = true
  })
}

function loginActionClient() {
  loading.value = true
  user = {email: "sofiamartinez@example.com", password: "1234"}
  login(user).catch((errorResponse) => {
    if (errorResponse.response.statusText.toString().startsWith("Cors"))
      message_error.value = "Ocurrio un error, por favor contacte al administrador"
    error.value = true
  })
}

</script>

<script>
export default {
  data: () => ({
    firstName: "",
    firstNameRules: [
      value => {
        if (value?.length > 3) return true

        return "First name must be at least 3 characters."
      },
    ],
    lastName: "123",
    lastNameRules: [
      value => {
        if (/[^0-9]/.test(value)) return true

        return "Last name can not contain digits."
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.v-footer-style {
  /*  padding-bottom: 10px;*/
  /*background-color: #f4f7fa;*/
  background-color: #f4f7fa;
  color: black;
  align-items: center;
  justify-content: center;
}

.bg-footer {
  position: absolute;
  bottom: 0px;
  align-items: center;
  background-color: #f4f7fa;
  color: black;
  bottom: 0;
  height: 30px;
}

.v-text-field {
  width: 300px;
}

.v-submit {
  width: 346px;
}

.font-setting {
  font-size: 24px;
  color: #777;
}

.custom-font {
  font-family: "Montserrat-Regular", sans-serif;
}
</style>
