<template>
    <div class="d-lg-none d-md logo-style-img mx-auto">
        <v-img class="d-flex w-100" src="/img/img.png"/>
    </div>
    <div class="center d-lg-none d-md-block logo-style">
        
        <p>Portal del Cliente</p>
    </div>    
</template>

<script>
  export default {
    name: "AppLogo"
  }
</script>

<style scoped>
.logo-style{
    color: black;
    font-size: 33px;
    font-weight: bold;
    background-color: #f4f7fa;
}    

.logo-style-img{
    background-color: black;
}
</style>
