import axios from "axios"
import {siteStore} from "@/config/store/site.store"

let site = {}
const axios_api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
})

axios_api.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axios_api.defaults.headers.get["Accepts"] = "application/json"

axios_api.pending = null
axios_api.interceptors.request.use(
    config => {
        site = siteStore()
        if (site.user) {
            if (config.url.endsWith("logout")) {
                config.headers["Refresh-Token"] = site.refresh_token
            }
            config.headers.Authorization = "Bearer " + (!config.url.endsWith("refresh") ? site.jwt : site.refresh_token)
        }
        return config
    }
)
axios_api.interceptors.response.use(
    res => {
        return res
    },
    async error => {
        site = siteStore()
        const originalRequest = error.config
        if (error.response)
            if (error.response.request.status === 403 && error.config.headers.Authorization.length > 7) {
                if (!error.response.request.responseURL.endsWith("refresh")) {
                    await site.refresh()
                    originalRequest.headers.Authorization = "Bearer " + site.jwt
                    return axios(originalRequest)
                } else {
                    site.logout()
                }
            }
        error = error.response ? error : {

            response: {
                data: {
                    message: "Cors error,Check preflight request, there is not response from server"
                },
                statusText: "Cors Errors , There no status text"
            }
        }
        throw error
    }
)
// axios_api.interceptors.request.eject(reqInt)
// axios_api.interceptors.response.eject(respInt)
export default axios_api
export const Axios = axios_api

