import moment from "moment"

export const columns = [
    {
        title: "Estado",
        align: "center",
        key: "status.name",
        sortable: true
    },
    {
        title: "Albarán",
        value: "delivery_note.code",
        align: "center",
        key: "delivery_note.code",
        sortable: true
    },
    {
        title: "Cliente",
        value: "delivery_note.client.code",
        align: "center",
        key: "delivery_note.client.code",
        sortable: true
    },
    {
        title: "Fecha de Albarán",
        value: (item)=>{ return moment(item.delivery_note.created_at).format('DD-MM-YYYY hh:mm a')},
        align: "center",
        key: "delivery_note.created_at",
        sortable: true
    },
    {
        title: "Description",
        value: "description",
        align: "center",
        key: "description",
        sortable: true
    },
    {
        title: "Fecha de solicitud",
        value: (item)=>{ return moment(item.created_at).format('DD-MM-YYYY hh:mm a')},
        align: "center",
        key: "created_at",
        sortable: true
    },
    {
        title: "Cargo",
        value: "charge",
        align: "center",
        key: "charge",
        sortable: true
    },
    {
        title: "Ean_code",
        value: "ean_code",
        align: "center",
        key: "ean_code",
        sortable: true
    },
    {
        title: "Acciones",
        key: "actions",
        align: "center",
        sortable: false
    }
]
