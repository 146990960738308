/**Generate by ASGENS
 *@author Charlietyn
 *@date Tue Jun 20 13:00:51 GMT-04:00 2023
 *@time Tue Jun 20 13:00:51 GMT-04:00 2023
 */

/**
 * Returns booleam if  the object contains this value in one of the attributes
 * @param {Object} object the object to compare
 * @param {String} value to contain
 */
export function filter_object(object, value) {
    if (!value) {
        return true
    }
    const keys = Object.keys(object)
    let i
    for (i of keys) {
        if (object[i] == null) {
            continue
        }
        if (object[i].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1) {
            return true
        }
    }
}

export function compare_object(object1, object2) {
    return Object.keys(object1).every(function (element) {
        return Object.keys(object2).find(function (element2) {
            return element === element2
        })
    })
}

export function debounce(fn, delay) {
    var timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export function getColorStatus(item) {
    if (item.status.name === "Aprobado") return "green"
    if (item.status.name === "Enviado Cliente") return "blue"
    if (item.status.name === "Recibido") return "#006400FF"
    if (item.status.name === "Rechazado") return "red"
    if (item.status.name === "Rechazado Cliente") return "#e879f9"
    if (item.status.name === "Aprobado con Cargos") return "#228B22FF"
    if (item.status.name === "Abonado") return "#483D8BFF"
}

export function getColor(item) {
    if (item === "Aprobado") return "green"
    if (item === "Enviado Cliente") return "blue"
    if (item === "Recibido") return "#006400FF"
    if (item === "Rechazado") return "red"
    if (item === "Rechazado Cliente") return "#e879f9"
    if (item === "Aprobado con Cargos") return "#228B22FF"
    if (item === "Abonado") return "#483D8BFF"
}

export function getColorStatusId(item) {
    if (item === 5) return ["Aprobado","green"]
    if (item === 1) return ["Enviado Cliente","blue"]
    if (item === 2) return ["Recibido","#006400FF"]
    if (item === 3) return ["Rechazado","red"]
    if (item === 4) return ["Rechazado Cliente","#e879f9"]
    if (item === 6) return ["Aprobado con Cargos","#228B22FF"]
    if (item === 7) return ["Abonado","#483D8BFF"]
}

export function selected_row(e, deselect = false) {
    if (e.target && !deselect) {
        let allElements = e.target.parentNode.parentNode.querySelectorAll("*")
        allElements.forEach(el => el.classList.remove("v-data-table__selected"))
        e.target.parentNode.classList.add("v-data-table__selected")
    }
    if (deselect) {
        let allElements = document.querySelectorAll("*")
        allElements.forEach(el => el.classList.remove("v-data-table__selected"))
    }
}

export function process_data(v) {

    const head = v.columns.filter(element => element.key !== "action_elements" && !element.hide_on_export).map(element => {
        return element.title
    })
    const data = v.data.map((element) => {
        return v.columns.filter(column => column.key !== "action_elements" && !column.hide_on_export).map((column) => {
            let nested = null
            if (column.key.indexOf(".") !== -1) {
                column.key.split(".").forEach((index) => {
                    nested = !nested ? element[index] : nested[index]
                })
            }
            return column.key.indexOf(".") === -1 ? element[column.key] : nested
        })
    })
    return {head, data}
}

export function exportToExcelVinstance(v) {
    const {head, data} = process_data(v)
    exportToExcel(head, data, v.$options.name)
}

export function exportToCSV(v) {
    const {head, data} = process_data(v)
    let csvContent = [head].concat(data).map(e => e.join(",")).join("\n")
    const hiddenElement = document.createElement("a")
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent)
    hiddenElement.target = "_blank"
    hiddenElement.download = v.title + ".csv"
    hiddenElement.click()
}

export function extract_user_data(name, token) {
    const user_data = jwtDecode(token).payload
    return user_data ? {[name]: user_data.sub, ...user_data} : null
}

export function exportToExcel(head, data, title = "export.xls") {
    const uri = "data:application/vnd.ms-excel;base64,"
    const template = "" +
        "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:x=\"urn:schemas-microsoft-com:office:excel\" xmlns=\"http://www.w3.org/TR/REC-html40\"><meta http-equiv=\"content-type\" content=\"application/vnd.ms-excel; charset=UTF-8\">" +
        "<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>" +
        "<body>" +
        "<table>" +
        "{table}" +
        "</table>" +
        "</body>" +
        "</html>"
    let table = "<thead><tr>"
    head.forEach((element) => {
        table += "<th style=\"width: 250px\">" + element + "</th>"
    })
    table += "</tr></thead><tbody>"
    data.forEach((element) => {
        table += "<tr>"
        element.forEach((data) => {
            table += "<td>" + data + "</td>"
        })
        table += "</tr>"
    })
    table += "</tbody>"

    const base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)))
    }
    const format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p]
        })
    }

    const ctx = {
        worksheet: "Worksheet",
        table: table
    }
    const link = document.createElement("a")
    link.download = title
    link.href = uri + base64(format(template, ctx))
    link.click()
}

export function allow_character(elEvento, permitidos) {
    // Variables que definen los caracteres permitidos
    var numeros = "0123456789"
    var decimal = "0123456789.-"
    var decimal_pos = "0123456789.-"
    var date = "0123456789/"
    var time = "0123456789:"
    var issn = "0123456789-"
    var none = ""
    var caracteres = " abcdefghijklmn?opqñrstuvwxyzABCDEFGHIJKLMÑN?OPQRSTUVWXYZéáíóú@_.,$%^&*()_+!~"
    var numeros_caracteres = numeros + caracteres
    var teclas_especiales = []
    // 8 = BackSpace, 46 = Supr, 37 = flecha izquierda, 39 = flecha derecha
    // Seleccionar los caracteres a partir del par?metro de la funci?n
    switch (permitidos) {
        case "num":
            permitidos = numeros
            break
        case "dec_pos":
            permitidos = decimal_pos
            break
        case "car":
            permitidos = caracteres
            break
        case "num_car":
            permitidos = numeros_caracteres
            break
        case "dec":
            permitidos = decimal
            break
        case "date":
            permitidos = date
            break
        case "time":
            permitidos = time
            break
        case "issn":
            permitidos = issn
            break
        case "none":
            permitidos = none
            break
    }

    // Obtener la tecla pulsada
    var evento = elEvento || window.event
    var codigoCaracter = evento.charCode || evento.keyCode
    var caracter = String.fromCharCode(codigoCaracter)

    // Comprobar si la tecla pulsada es alguna de las teclas especiales
    // (teclas de borrado y flechas horizontales)
    var tecla_especial = false
    for (var i in teclas_especiales) {
        if (codigoCaracter === teclas_especiales[i]) {
            tecla_especial = true
            break
        }
    }
    // Comprobar si la tecla pulsada se encuentra en los caracteres permitidos
    // o si es una tecla especial
    return permitidos.indexOf(caracter) !== -1 || tecla_especial

}

export function print(elementId, title, selector = "id") {
    const content = selector === "id" ? document.getElementById(elementId).outerHTML : document.getElementsByClassName(elementId)[0].outerHTML
    const printWindow = window.open("", "_blank")
    printWindow.document.write("<html><head><title>" + title + "</title></head><body>")
    printWindow.document.write("<style>" +
        "h1{color: #f50606}" +
        ".v-table__wrapper > table > tbody > tr:not(:last-child) > td, .v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > th {\n" +
        "    border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));\n" +
        "}\n" +
        ".v-table__wrapper > table > thead > tr > td.v-data-table-column--align-center, .v-data-table .v-table__wrapper > table > thead > tr th.v-data-table-column--align-center, .v-data-table .v-table__wrapper > table tbody > tr > td.v-data-table-column--align-center, .v-data-table .v-table__wrapper > table tbody > tr th.v-data-table-column--align-center {\n" +
        "    text-align: center;\n" +
        "}\n" +
        ".v-table__wrapper > table > tbody > tr > td, .v-table > .v-table__wrapper > table > thead > tr > td, .v-table > .v-table__wrapper > table > tfoot > tr > td {\n" +
        "    height: var(--v-table-row-height);\n" +
        "}\n" +
        ".v-table__wrapper > table > tbody > tr > td, .v-table > .v-table__wrapper > table > tbody > tr > th, .v-table > .v-table__wrapper > table > thead > tr > td, .v-table > .v-table__wrapper > table > thead > tr > th, .v-table > .v-table__wrapper > table > tfoot > tr > td, .v-table > .v-table__wrapper > table > tfoot > tr > th {\n" +
        "    padding: 0 16px;\n" +
        "    transition-duration: 0.28s;\n" +
        "    transition-property: box-shadow, opacity, background, height;\n" +
        "    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n" +
        "}\n" +
        "tbody tr:nth-of-type(odd) {\n" +
        "    background-color: rgba(0, 0, 0, .10);\n" +
        "}\n" +
        "thead, tbody, tfoot, tr, td, th {\n" +
        "    border-color: inherit;\n" +
        "    border-style: solid;\n" +
        "    border-width: 0;\n" +
        "}</style>")

    printWindow.document.write("<h1>" + title + "</title></h1>")
    printWindow.document.write(content)
    printWindow.document.write("</body></html>")
    printWindow.document.close()
    printWindow.onload = function () {
        printWindow.print()
    }
}

export async function copy(data, header, title) {
    let copyValue = ""
    copyValue = copyValue + title + "\n"
    header.forEach((e) => {
        copyValue += e.title.concat("\t")
    })
    copyValue += "\n"
    data.forEach((e) => {
        const values = Object.values(e)
        values.forEach(e => {
            copyValue += e.toString().concat("\t")
        })
    })
    await navigator.clipboard.writeText(copyValue)
}

export function filter_object_column(object, value, columns) {
    if (!value) {
        return true
    }
    let i
    let iter = 0
    const keys = Object.values(columns)
    for (i of keys) {

        if (!i.value && !i.use_filter) {
            if (iter >= keys.length) {
                return false
            }
            continue
        }

        let array = i.key.split(".")
        if (object[array.length > 0 ? array[0] : i.key] instanceof Object) {
            let data = object
            array.forEach((iter) => {
                if (data) {
                    data = data[iter]
                }
            })
            if (data != null && data.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                return true
            }
        } else {
            if (object[array[0]] != null && object[array[0]].toString().toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                return true
            }
        }
    }
}


export function capitalize(s) {
    if (typeof s !== "string") return s
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export function dynamic_import(regexp, requireModule, withModule = false) {
    const classes = {}
    requireModule.keys().forEach(fileName => {
        const route = fileName.split("/")
        const module = withModule && route[route.indexOf("modules") + 1] !== "." ? route[route.indexOf("modules") + 1] + "." : ""
        const moduleName = module + capitalize(fileName.replace(regexp, "").substr(fileName.replace(regexp, "").lastIndexOf("/") + 1, fileName.length))
        classes[moduleName] = {
            ...requireModule(fileName)
        }
    })
    return classes
}


export const jwtDecode = function (t) {
    let token = {}
    token.raw = t
    token.header = JSON.parse(window.atob(t.split(".")[0]))
    token.payload = JSON.parse(window.atob(t.split(".")[1]))
    return (token)
}

export const convert_to_phone = function (t) {
    if (t) {
        var x = t.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        return !x[2] ? x[1] : "(" + x[1] + ")" + x[2] + (x[3] ? "-" + x[3] : "")
    }
    return ""
}

export const objectToFormData = function (obj, form, namespace) {
    var fd = form || new FormData()
    var formKey
    for (var property in obj) {
        if (obj[property] === undefined) {
            continue
        }
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + "[" + property + "]"
            } else {
                formKey = property
            }
            if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {

                objectToFormData(obj[property], fd, property)

            } else {

                // if it's a string or a File object
                const value = obj[property] === true || obj[property] === false ? +obj[property] : obj[property]
                fd.append(formKey, value)
            }

        }
    }

    return fd

}
export const convertToFormData = function (model, form, namespace) {
    const formData = form || new FormData()
    for (const propertyName in model) {
        if (!Object.prototype.hasOwnProperty.call(model, propertyName) ||
            model[propertyName] === undefined)
            continue
        const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
        if (model[propertyName] instanceof Date) {
            formData.append(formKey, this.dateTimeToString(model[propertyName]))
        } else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach((element, index) => {
                if (typeof element != "object")
                    formData.append(`${formKey}[]`, element)
                else {
                    const tempFormKey = `${formKey}[${index}]`
                    convertToFormData(element, formData, tempFormKey)
                }
            })
        } else if (typeof model[propertyName] === "object" && !(model[propertyName] instanceof File)) {
            convertToFormData(model[propertyName], formData, formKey)
        } else {
            formData.append(formKey, model[propertyName].toString())
        }
    }
    return formData
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0
}

export const birthDateFromCI = (ci) => {
    const year = ci.substr(0, 2)
    const month = ci.substr(2, 2)
    const day = ci.substr(4, 2)
    return new Date(`${year}/${month}/${day}`)
}

export const validateNumber = (e) => {
    let key = e.keyCode
    if (key < 48 || key > 57) {
        e.preventDefault()
    }
}

export const changeSelectTitle = (value) => {
    document.querySelector(".phone_code .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value").innerText = `+${value}`
}

export const getBirthDate = (ci) => {
    const year = parseInt(ci.substr(0, 2))
    const month = ci.substr(2, 2)
    const day = ci.substr(4, 2)
    const dateString = `${year > 20 ? "19" : "20"}${year}/${month}/${day}`
    return new Date(dateString)
}
export const getSex = (ci) => {
    const sexNumber = ci[ci.length - 2]
    return sexNumber % 2 === 0 ? "Masculino" : "Femenino"
}

export const getAge = (date) => {
    let ageDifMs = Date.now() - new Date(date).getTime()
    let ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export function set_proxy(obj) {
    "use strict"
    if (typeof Proxy == "undefined") {
        throw new Error("This browser doesn't support Proxy")
    }
    obj = new Proxy(obj, {
        set(target, name, value, receiver) {
            if (!Reflect.has(target, name)) {
                console.warn(`Setting non-existent property '${name}', initial value: ${value}`)
            } else {
                target._changed_attributes = Object.assign(target._changed_attributes || {}, {[name]: value})
            }
            return Reflect.set(target, name, value, receiver)
        }
    })
    return obj
}




