
export default [
    {
        path: "/guarantee",
        name: "guarantee",
        component: () => import("@/modules/managment/guarantee/views/GuaranteeView.vue"),
    },
    {
        path: '/newguarantee',
        name: 'newguarantee',
        component: () => import("@/modules/managment/guarantee/views/NewGuarantee.vue"),
    },
    {
        path: '/detailguarantee/:id',
        name: 'detailguarantee',
        props: true,
        component: () => import("@/modules/managment/guarantee/views/DetailGuarantee.vue"),
    },
]
