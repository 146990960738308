import moment from "moment"

export const columns = [
    {
        title: "Estado",
        align: "center",
        key: "status.name",
        sortable: true
    },
    {
        title: "Albarán",
        value: "delivery_note.code",
        align: "center",
        key: "delivery_note.code",
        sortable: true
    },
    {
        title: "Cliente",
        value: "delivery_note.client.code",
        align: "center",
        key: "delivery_note.client.code",
        sortable: true
    },
    {
        title: "Mounting_date",
        value: (item)=>{ return moment(item.mounting_date).format('DD-MM-YYYY hh:mm a')},
        align: "center",
        key: "mounting_date",
        sortable: true
    },
    {
        title: "Fecha de Garantía",
        value: (item)=>{ return moment(item.claim_date).format('DD-MM-YYYY hh:mm a')},
        align: "center",
        key: "claim_date",
        sortable: true
    },
    {
        title: "Fecha de Albarán",
        value: (item)=>{ return moment(item.created_at).format('DD-MM-YYYY hh:mm a')},
        align: "center",
        key: "delivery_note.created_at",
        sortable: true
    },
    {
        title: "Claim_km",
        value: "claim_km",
        align: "center",
        key: "claim_km",
        sortable: true
    },
    {
        title: "Claim_actual_km",
        value: "claim_actual_km",
        align: "center",
        key: "claim_actual_km",
        sortable: true
    },
    {
        title: "Brand_vehicle",
        value: "brand_vehicle",
        align: "center",
        key: "brand_vehicle",
        sortable: true
    },
    {
        title: "Plate_vehicle",
        value: "plate_vehicle",
        align: "center",
        key: "plate_vehicle",
        sortable: true
    },
    {
        title: "Vin_vehicle",
        value: "vin_vehicle",
        align: "center",
        key: "vin_vehicle",
        sortable: true
    },
    {
        title: "Motive",
        value: "motive",
        align: "center",
        key: "motive",
        sortable: true
    },
    {
        title: "Details",
        value: "details",
        align: "center",
        key: "details",
        sortable: true
    },
    {
        title: "Files",
        value: "files",
        align: "center",
        key: "files",
        sortable: true
    },
    {
        title: "Charge",
        value: "charge",
        align: "center",
        key: "charge",
        sortable: true
    },
    {
        title: "Ean_code",
        value: "ean_code",
        align: "center",
        key: "ean_code",
        sortable: true
    },
    {
        title: "Acciones",
        key: "actions",
        align: "center",
        sortable: true
    }
]
